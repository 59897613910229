/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Formik, FormikProps } from "formik";
import { FC, useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router";

import Notifications from "web/Layout/Notifications";

import Check from "web/assets/icons/check-circle-outlined.svg";

import { validateFields } from "web/utils/system/formValidator/validation";

import { useUpdateInactiveEmployeePasswordMutation } from "web/features/catalogManagement/catalogManagementApiSlice";

import createCodePasswordCopy from "../createCodePasswordCopy";
import classes from "./container.scss";
import CreateCodePasswordForm from "./createCodePasswordForm";

interface ICreateCodePasswordFormContainerProps {
  language: "PL" | "EN";
}

const initialValues = {
  password: "",
  confirmPassword: "",
};

const CreateCodePasswordFormContainer: FC<
  ICreateCodePasswordFormContainerProps
> = ({ language }) => {
  const formikRef = useRef<FormikProps<typeof initialValues>>(null);
  const { hash } = useParams<{ hash: string }>();
  const [updatePassword, { isLoading, isSuccess }] =
    useUpdateInactiveEmployeePasswordMutation();

  const onSubmit = (values: typeof initialValues) => {
    updatePassword({
      hash,
      password: values.password,
    });
  };

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  }, [language]);

  const { validatePassword } = useMemo(() => {
    const langKey = language === "EN" ? "en_GB" : "pl_PL";
    const passwordFields = [
      {
        name: "password",
        rules: ["required", "validateLongPassword"],
        selectedLanguage: langKey,
      },
      {
        name: "confirmPassword",
        rules: ["required", "confirmPassword"],
        selectedLanguage: langKey,
      },
    ];
    return {
      validatePassword: validateFields(passwordFields),
    };
  }, [language]);

  return (
    <section className={`${classes.root} ${isLoading ? classes.loading : ""}`}>
      <Notifications />

      {isSuccess ? (
        <div className={classes.success}>
          <Check className={classes.check} />
          <h1 className={classes.title}>
            {createCodePasswordCopy.successHeader[language]}
          </h1>
          <p className={classes.description}>
            {createCodePasswordCopy.successDescription1[language]}
          </p>
          <p className={classes.description}>
            {createCodePasswordCopy.successDescription2[language]}
          </p>
        </div>
      ) : (
        <div className={classes.wrapper}>
          <h1 className={classes.title}>
            {createCodePasswordCopy.header[language]}
          </h1>
          <p className={classes.description}>
            {createCodePasswordCopy.description[language]}
          </p>
          <Formik
            // @ts-ignore
            innerRef={formikRef}
            initialValues={initialValues}
            // @ts-ignore
            onSubmit={onSubmit}
            validate={validatePassword}
          >
            <CreateCodePasswordForm isLoading={isLoading} language={language} />
          </Formik>
        </div>
      )}
    </section>
  );
};

export default CreateCodePasswordFormContainer;
