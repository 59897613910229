// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.createCodePasswordLinkBox-links-R2x{font-size:1.0625rem;line-height:2.6rem}`, "",{"version":3,"sources":["webpack://./web/Pages/CreateCodePassword/CreateCodePasswordLinkBox/createCodePasswordLinkBox.scss"],"names":[],"mappings":"AAIuB,qCACrB,mBAAA,CACA,kBAAA","sourcesContent":["@import \"web/assets/styles/functions\";\n                       @import \"web/assets/styles/variables\";\n                       @import \"web/assets/styles/mixins/grid\";\n                       @import \"web/assets/styles/mixins/breakpoints\";\n                       .links {\n  font-size: 1.0625rem;\n  line-height: 2.6rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"links": `createCodePasswordLinkBox-links-R2x`
};
export default ___CSS_LOADER_EXPORT___;
