import { FC } from "react";

import createCodePasswordCopy from "../createCodePasswordCopy";
import classes from "./createCodePasswordLinkBox.scss";

interface ICreateCodePasswordLinkBoxProps {
  language: "PL" | "EN";
}

const CreateCodePasswordLinkBox: FC<ICreateCodePasswordLinkBoxProps> = ({
  language,
}) => {
  return (
    <div className={classes.links}>
      <a href={createCodePasswordCopy.customerService.link[language]}>
        {createCodePasswordCopy.customerService.text[language]}
      </a>{" "}
      |{" "}
      <a href={createCodePasswordCopy.privacyPolicy.link[language]}>
        {createCodePasswordCopy.privacyPolicy.text[language]}
      </a>{" "}
      |{" "}
      <a href={createCodePasswordCopy.cookieSettings.link[language]}>
        {createCodePasswordCopy.cookieSettings.text[language]}
      </a>
    </div>
  );
};

export default CreateCodePasswordLinkBox;
