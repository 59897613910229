import restUrls from "web/constants/restUrls";

import api from "../api/apiSlice";

export const catalogManagementApiSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getInactiveEmployeeCodes: builder.query<void, string>({
      query: (hash) => ({
        url: `${restUrls.employeeInactiveCodes}/${hash}`,
        omitToken: true,
      }),
    }),
    updateInactiveEmployeePassword: builder.mutation<
      void,
      { hash: string; password: string }
    >({
      query: ({ hash, password }) => ({
        url: `${restUrls.employeeInactiveCodes}/${hash}`,
        method: "PATCH",
        body: JSON.stringify({ password }),
        omitToken: true,
        headers: {
          "Content-type": "application/merge-patch+json",
        },
      }),
    }),
  }),
});

export const {
  useGetInactiveEmployeeCodesQuery,
  useUpdateInactiveEmployeePasswordMutation,
} = catalogManagementApiSlice;

export default catalogManagementApiSlice.reducer;
