/* eslint-disable react/no-danger */
import { FC } from "react";

import Notifications from "web/Layout/Notifications";

import classes from "./error.scss";

interface IErrorProps {
  message?: string;
  hasButton?: boolean;
}

const Error: FC<IErrorProps> = ({
  message = ` Wystąpił błąd. Skontaktuj się z administratorem. / An error occured.
        Please contact us.`,
  hasButton = false,
}) => {
  return (
    <div className={`container ${classes.root}`}>
      <Notifications />
      <p
        className={classes.text}
        dangerouslySetInnerHTML={{ __html: message }}
      />
      {window?.location?.replace && hasButton && (
        <button
          className={classes.button}
          type="button"
          onClick={() => window.location.replace("/")}
        >
          Wróć na stronę główną / Back to Homepage
        </button>
      )}
    </div>
  );
};

export default Error;
