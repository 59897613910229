import { isEmpty } from "lodash";

import { ISubscriptionActiveForm, RequirePeselValue } from "web/types/Subscription";

import __ from "web/Layout/Translations";
import type { StringNumber } from "web/types/Utils";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";

import getBirthDateFromPesel from "web/utils/getBirthDateFromPesel";
import checkIfTimePassed from "web/utils/data/validator/dateAndTime/checkIfTimePassed";

import { dayMilliseconds } from "web/constants/date";

import checkIfDateIsValid from "./checkIfDateIsValid";
import emailRegex from "./emailRegex";



const isPasswordComplexEnough = (str = "") => {
  const rules = [
    (char: string) => /[A-Z]/.test(char),
    (char: string) => /[a-z]/.test(char),
    (char: string) => /\d/.test(char),
    (char: string) => /[^A-Za-z0-9]/.test(char),
  ];
  const rulesMatchCount = rules.reduce(
    (result, current) => (current(str) ? result + 1 : result),
    0
  );

  return rulesMatchCount === rules.length;
};

const checkPasswordContainsInvalidChars = (str = "") => {
  return !/^[a-zA-Z0-9`~!@#$%^&*()\-+=,|[\]{}'"\\./<>?]*$/.test(str);
};

const choseProperNotificationBySelectedLanguage =  (selectedLanguage: string | undefined, textPL: string, textEN: string) =>
selectedLanguage && selectedLanguage === 'en_GB' ? __(textEN) :  __(textPL);

const isValidPesel = (pesel: string) => {
  const weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
  let sum;
  const controlNumber = parseInt(pesel.substring(10, 11), 10);
  sum = weight.reduce((acc, val, i) => {
    return acc + parseInt(pesel.substring(i, i + 1), 10) * val;
  }, 0);
  sum %= 10;
  if (!((10 - sum) % 10 === controlNumber)) {
    return false;
  }

  const day = Number(pesel.substring(4, 6));
  const lastTwoYearDigits = Number(pesel.substring(0, 2));
  let month;
  let year;

  const monthWithCentury = Number(pesel.substring(2, 4));
  if (monthWithCentury >= 1 && monthWithCentury <= 12) {
    year = 1900 + lastTwoYearDigits;
    month = monthWithCentury;
  } else if (monthWithCentury >= 21 && monthWithCentury <= 32) {
    year = 2000 + lastTwoYearDigits;
    month = monthWithCentury - 20;
  } else if (monthWithCentury >= 41 && monthWithCentury <= 52) {
    year = 2100 + lastTwoYearDigits;
    month = monthWithCentury - 40;
  } else if (monthWithCentury >= 61 && monthWithCentury <= 72) {
    year = 2200 + lastTwoYearDigits;
    month = monthWithCentury - 60;
  } else if (monthWithCentury >= 81 && monthWithCentury <= 92) {
    year = 1800 + lastTwoYearDigits;
    month = monthWithCentury - 80;
  }

  const javascriptMonth = (month as number) - 1;
  return checkIfDateIsValid(year as number, javascriptMonth, day);
};

const validatePesel = ({ value, selectedLanguage }: { value: string, selectedLanguage?: string }) => {
  return value !== "" && !(/^[0-9]{11}$/.test(value) && isValidPesel(value))
  ? choseProperNotificationBySelectedLanguage(selectedLanguage, "Wprowadź poprawny PESEL", "Enter the correct PESEL number")
    : null;
};

export const validateRequired = ({ value, selectedLanguage }: { value: unknown, selectedLanguage?: string }) => {
  return value || value === 0 ? null : choseProperNotificationBySelectedLanguage(selectedLanguage, "Pole jest wymagane", "The field is required");
};

const validateBirthDatePeselMatch = (birthDate: StringNumber, pesel: string, selectedLanguage?: string) => {
  if (!pesel || pesel.replaceAll("_", "").length < 6) return null;
  const birthDateFromPesel = (<Date>getBirthDateFromPesel(pesel)).getTime();
  return birthDateFromPesel !== +birthDate
  ? choseProperNotificationBySelectedLanguage(selectedLanguage, 
    "Wprowadzona data urodzenia i data z nr pesel nie są takie same.", 
    "The entered date of birth and the date with the PESEL number are not the same.")
    : null;
};

const checkField = (value: unknown) => {
  if (value && typeof value === "object") {
    return !isEmpty(value);
  }
  return value;
};

export default {
  required: validateRequired,
  requiredDependent: ({ value, values, ruleAdditional, selectedLanguage }:
    {value: unknown, values: object, ruleAdditional: keyof typeof values, selectedLanguage?: string}) => {
    return checkField(value) || checkField(values[ruleAdditional])
      ? null
      : choseProperNotificationBySelectedLanguage(selectedLanguage, "Pole jest wymagane", "The field is required")
  },
  name: ({ value, selectedLanguage }:{value: string, selectedLanguage?: string}) => {
    return !/^[/' \p{L}\p{N}\p{M}-]+$/u.test(value)
    ? choseProperNotificationBySelectedLanguage(selectedLanguage, "Wprowadź litery. Myślnik oraz spacje są dopuszczalne", "Enter letters. A dash and spaces are allowed")
      : null;
  },
  email: ({ value, selectedLanguage } :{value: string, selectedLanguage?: string}) => {
    return !value || 
    value.match(emailRegex)
      ? null
      : choseProperNotificationBySelectedLanguage(selectedLanguage, "Niepoprawny adres email", "Incorrect email address");
  },
  confirmEmail: ({ value, values }: {value: string, values: ISubscriptionActiveForm}) => {
    const validEmail =
      !value || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);

    if (!validEmail) {
      return __("Niepoprawny adres email");
    }

    if (value !== values?.receiverEmail) {
      return __("Adresy email nie są identyczne.");
    }

    return null;
  },
  emailsArray: ({ value }: {value: string | string[]}) => {
    const emails = isArrayHasItems(value)? value : value?.split(',')
    return !value || emails?.every((email: string) =>email.match(emailRegex))
      ? null
      : __("Prosimy wpisać poprawny adres lub adresy e-mail");
  },
  numeric: ({ value, selectedLanguage }: {value: string, selectedLanguage?: string}) => {
    return value !== "" && !/^-?\d+$/.test(value) ?
choseProperNotificationBySelectedLanguage(selectedLanguage, "Wprowadź tylko cyfry", "Enter numbers only") : null;
  },
  pesel: validatePesel,
  requirePesel: ({ value, selectedLanguage}: 
    {value: {pesel: string, babyOrForeigner: boolean, birthDate: StringNumber}, selectedLanguage?: string}) => {
    const babyOrForeignerValidationPesel = validatePesel({ value: value.pesel });

    const peselValidation =
      validateRequired({ value: value.pesel, selectedLanguage }) ||
      validatePesel({ value: value.pesel, selectedLanguage });

    const validationData = {
      pesel: value.babyOrForeigner
        ? babyOrForeignerValidationPesel
        : peselValidation,
      birthDate: value.birthDate
      ? validateBirthDatePeselMatch(value.birthDate, value.pesel, selectedLanguage)
        : validateRequired({ value: value.birthDate, selectedLanguage }),
    };

    const hasAnyErrorMessages = Object.values(validationData).some(
      (errorMessage) => !!errorMessage
    );
    return hasAnyErrorMessages ? validationData : null;
  },
  dependentOnRequirePesel: ({ value, values, ruleAdditional, selectedLanguage }: 
    {value: unknown, values: object, ruleAdditional: keyof typeof values, selectedLanguage?: string}) => {
      if ((values[ruleAdditional] as RequirePeselValue).babyOrForeigner && !checkField(value)) {
        return choseProperNotificationBySelectedLanguage(selectedLanguage, "Pole jest wymagane", "The field is required");
      }
      return null;
  },
  postcode: ({ value, selectedLanguage }:  {value: string, selectedLanguage?: string}) => {
    return value !== "" && !/^([0-9]{2})(-[0-9]{3})$/i.test(value)
    ? choseProperNotificationBySelectedLanguage(selectedLanguage, "Wprowadź poprawny kod pocztowy", "Enter a valid postal code")
      : null;
  },
  float: ({ value }: {value: StringNumber}) => {
    const val = Number(value?.toString().replace(",", "."));
    return Number.isNaN(val)
      ? __(`Wprowadź tylko cyfry w formacie 0.0 lub 0,0`)
      : null;
  },
  positive: ({ value }: {value: StringNumber}) => {
    return +value?.toString().replace(",", ".") > 0
      ? null
      : __(`Wprowadź tylko dodatnie wartości`);
  },
  phone: ({ value, selectedLanguage }: {value: string, selectedLanguage?: string}) => {
    if(value !== "" && !value?.match(/^[0-9]+$/g)) {
      return choseProperNotificationBySelectedLanguage(selectedLanguage, "Pole może zawierać tylko cyfry", "The field can only contain numbers");
    }

    if(value !== "" && !value?.match(/[0-9]{9,}$/)) {
      return choseProperNotificationBySelectedLanguage(selectedLanguage, "Musi zawierać minimum 9 cyfr", "Must contain at least 9 digits");
    }

    return null;
  },
  fullPhone: ({ value }:{value: string}) => {
    return value !== "" && !value?.match(/^[0-9]{11,}$/)
      ? __(`Musi zawierać minimum 11 cyfr`)
      : null;
  },
  phoneNumber: ({ value, selectedLanguage } : { value: string, selectedLanguage?: string }) => {
    return value !== "" && !value?.match(/[0-9]{9,}$/)
    ? choseProperNotificationBySelectedLanguage(selectedLanguage, "Musi zawierać minimum 9 cyfr", "Must contain at least 9 digits")
      : null;
  },
  minLength: ({ value, ruleAdditional, selectedLanguage }:{value: string, ruleAdditional: number, selectedLanguage?: string}) => {
    const minLenghtText = choseProperNotificationBySelectedLanguage(selectedLanguage, "Musi zawierać minimalnie %1 znaków", "Must contain a minimum of %1 characters");
    return value && value.length < ruleAdditional
    ? minLenghtText.replace("%1", ruleAdditional?.toString())
      : null;
  },
  maxLength: ({ value, ruleAdditional, selectedLanguage }: {value: string, ruleAdditional: number, selectedLanguage?: string}) => {
    const maxLenghtText = choseProperNotificationBySelectedLanguage(selectedLanguage, "Musi zawierać maksymalnie %1 znaków", "Must contain a maximum of %1 characters");
    return value && value.length > ruleAdditional
    ? maxLenghtText.replace("%1", ruleAdditional?.toString())
      : null;
  },
  validatePassword: ({ value, selectedLanguage }: {value: string, selectedLanguage?: string}) => {
    if (checkPasswordContainsInvalidChars(value)) {
      return choseProperNotificationBySelectedLanguage(selectedLanguage, "Hasło zawiera niedozwolone znaki.", "Password contains forbidden characters.");
    }
    if (!value || value.length < 8 || !isPasswordComplexEnough(value)) {
      return choseProperNotificationBySelectedLanguage(selectedLanguage, "Hasło powinno zawierać:", "Password should contain:");
    }

    return null;
  },
  validateLongPassword: ({ value, selectedLanguage }: {value: string, selectedLanguage?: string}) => {
    if (checkPasswordContainsInvalidChars(value)) {
      return choseProperNotificationBySelectedLanguage(selectedLanguage, "Hasło zawiera niedozwolone znaki.", "Password contains forbidden characters.");
    }
    if (!value || value.length < 12 || !isPasswordComplexEnough(value)) {
      return choseProperNotificationBySelectedLanguage(selectedLanguage, "Hasło powinno zawierać:", "Password should contain:");
    }

    return null;
  },
  confirmPassword: ({ value, values, selectedLanguage }: {value: string, values: {password: string}, selectedLanguage?: string }) => {
    if (value !== values.password) {
      return choseProperNotificationBySelectedLanguage(selectedLanguage, "Hasła nie są identyczne.", "Passwords do not match.");
    }

    return null;
  },
  lessThenBookingCost: ({ value, values }: {value: StringNumber, values: { cost_all_booking: StringNumber}}) => {
    const valueProcessed =
      typeof value === "string" && parseFloat(value)
        ? parseFloat(value.replace(",", "."))
        : value;
    const costAllBooking =
      values &&
      typeof values.cost_all_booking === "string" &&
      parseFloat(values.cost_all_booking)
        ? parseFloat(values.cost_all_booking.replace(",", "."))
        : values?.cost_all_booking;
    if (valueProcessed > costAllBooking) {
      return __(
        "Płatność z MyBenefit jest większa niż wartość całej rezerwacji"
      );
    }

    return null;
  },
  hasOneDayPassed: ({ value, values }:  {value: string, values: { awaitsUpdate: boolean }}) => {
    if(!value) return null;
    
    return values.awaitsUpdate && checkIfTimePassed(value, dayMilliseconds)
      ? __(`Wyślij formularz ponownie`)
      : null;
  },
};
