import { FC, useState } from "react";

import type { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import Status from "./Status";
import FieldPassword from "./fieldPassword";
import defaultClasses from "./fieldPassword.scss";

interface IFieldPasswordContainerProps {
  label?: string;
  name: string;
  placeholder?: string;
  withStatus?: boolean;
  language: "PL" | "EN";
}

const FieldPasswordContainer: FC<
  PropsWithClasses<IFieldPasswordContainerProps>
> = ({
  label = "",
  name,
  classes = {},
  placeholder = "",
  withStatus = true,
  language,
}) => {
  const [isVisible, changeStatus] = useState(false);
  const type = isVisible ? "text" : "password";
  const toggleAction = () => changeStatus((status) => !status);

  return (
    <div className={classes.root}>
      <FieldPassword
        classes={classes}
        name={name}
        type={type}
        label={label}
        placeholder={placeholder}
        toggleAction={toggleAction}
        isVisible={isVisible}
      />
      {withStatus && <Status name={name} language={language} />}
    </div>
  );
};

export default classify<PropsWithClasses<IFieldPasswordContainerProps>>(
  defaultClasses
)(FieldPasswordContainer);
