/* eslint-disable max-len */
export enum CheckoutTypes {
  DEFAULT = "default",
  INTERNATIONAL_TOURISM = "internationalTourism",
  DOMESTIC_TOURISM = "domesticTourism",
  SUBSCRIPTION = "subscription",
  BUY_NOW = "buyNow",
}

export const checkoutExternalTypes = {
  prepaidCard: "prepaid-card",
};

export const checkoutSteps = {
  cart: "cart",
  payments: "payments",
  summary: "summary",
  information: "information",
  configuration: "configuration",
  agreements: "agreements",
  blocked: "blocked",
};

export const checkoutStepsMobile = {
  paymentSources: "paymentSourcesMobile",
  paymentMethod: "paymentMethodMobile",
  summaryInformation: "summaryInformationMobile",
};

export default {
  userRoutes: "/",
  home: "/",
  notFound: "/not-found",
  error: "/error",
  browserSettingsError: "/browser-settings-error",
  navigation: "/navigation",
  helpCenter: "/help-center",
  helpCenterCategory: "/help-center/category",
  helpCenterArticle: "/help-center/article",
  search: "/search",
  tourism: "/tourism",
  subscriptions: "/subscriptions",
  productsQuickList: "/productsQuickList",
  contactForm: "/contact-form",
  nativeAppLogin: "/native-app-login",
  checkoutSuccess: "/checkout_success/:orderId",
  checkoutTourismPending: "/tourism_checkout_pending/:orderId",
  checkoutSuccessPrepaidCard: "/checkout-success-prepaid-card/:orderId",
  checkoutFailed: "/checkout_failed",
  checkoutSplash: "/thank_you_page",
  checkoutSuccessDefault: `/checkoutSuccess/${CheckoutTypes.DEFAULT}`,
  checkoutSuccessInternationalTourism: `/checkoutSuccess/${CheckoutTypes.INTERNATIONAL_TOURISM}`,
  checkout: "/checkout/:type/:step",
  checkoutExternal: "/checkout-external/:type",
  checkoutExternalPrepaid: `/checkout-external/${checkoutExternalTypes.prepaidCard}`,
  checkoutUUID: `/checkout/:type/:step/:uuid`,
  checkoutDefaultCart: `/checkout/${CheckoutTypes.DEFAULT}/${checkoutSteps.cart}`,
  checkoutDefaultPayments: `/checkout/${CheckoutTypes.DEFAULT}/${checkoutSteps.payments}`,
  checkoutDefaultSummary: `/checkout/${CheckoutTypes.DEFAULT}/${checkoutSteps.summary}`,

  // international desktop
  checkoutInternationalTourismPayments: `/checkout/${CheckoutTypes.INTERNATIONAL_TOURISM}/${checkoutSteps.payments}`,
  // international mobile
  checkoutInternationalTourismPaymentSources: `/checkout/${CheckoutTypes.INTERNATIONAL_TOURISM}/${checkoutStepsMobile.paymentSources}`,
  checkoutInternationalTourismPaymentMethod: `/checkout/${CheckoutTypes.INTERNATIONAL_TOURISM}/${checkoutStepsMobile.paymentMethod}`,
  // international common
  checkoutInternationalTourismSummary: `/checkout/${CheckoutTypes.INTERNATIONAL_TOURISM}/${checkoutSteps.summary}`,

  // domestic desktop
  checkoutDomesticTourismPayments: `/checkout/${CheckoutTypes.DOMESTIC_TOURISM}/${checkoutSteps.payments}`,
  // domestic mobile
  checkoutDomesticTourismPaymentSources: `/checkout/${CheckoutTypes.DOMESTIC_TOURISM}/${checkoutStepsMobile.paymentSources}`,
  checkoutDomesticTourismPaymentMethod: `/checkout/${CheckoutTypes.DOMESTIC_TOURISM}/${checkoutStepsMobile.paymentMethod}`,
  checkoutDomesticTourismSummaryInformation: `/checkout/${CheckoutTypes.DOMESTIC_TOURISM}/${checkoutStepsMobile.summaryInformation}`,
  // domestic common
  checkoutDomesticTourismSummary: `/checkout/${CheckoutTypes.DOMESTIC_TOURISM}/${checkoutSteps.summary}`,
  checkoutDomesticTourismInformation: `/checkout/${CheckoutTypes.DOMESTIC_TOURISM}/${checkoutSteps.information}`,

  // buyNow desktop
  checkoutBuyNowPayments: `/checkout/${CheckoutTypes.BUY_NOW}/${checkoutSteps.payments}`,
  // buyNow mobile
  checkoutBuyNowPaymentSources: `/checkout/${CheckoutTypes.BUY_NOW}/${checkoutStepsMobile.paymentSources}`,
  checkoutBuyNowPaymentMethod: `/checkout/${CheckoutTypes.BUY_NOW}/${checkoutStepsMobile.paymentMethod}`,
  // buyNow common
  checkoutBuyNowSummary: `/checkout/${CheckoutTypes.BUY_NOW}/${checkoutSteps.summary}`,

  checkoutSubscriptionConfiguration: `/checkout/${CheckoutTypes.SUBSCRIPTION}/${checkoutSteps.configuration}`,
  checkoutSubscriptionInformation: `/checkout/${CheckoutTypes.SUBSCRIPTION}/${checkoutSteps.information}`,
  checkoutSubscriptionAgreements: `/checkout/${CheckoutTypes.SUBSCRIPTION}/${checkoutSteps.agreements}`,
  checkoutSubscriptionSummary: `/checkout/${CheckoutTypes.SUBSCRIPTION}/${checkoutSteps.summary}`,
  checkoutSubscriptionBlocked: `/checkout/${CheckoutTypes.SUBSCRIPTION}/${checkoutSteps.blocked}`,
  checkoutSubscriptionSuccess: `/checkout_success/${CheckoutTypes.SUBSCRIPTION}`,

  externalSubscriptionForm: "/benefits/guest-form",

  product: "/product",
  customer: "/customer",
  customerLogout: "/customer/account/logout",
  customerQuickView: "/customer/quickView",
  customerOrders: "/customer/orders",
  customerOrderDetails: "/customer/orders/:orderId",
  customerOrdersOrderProduct: "/customer/orders/:orderId/:productId",
  customerHistory: "/customer/history",
  customerCodes: "/customer/codes",
  customerSubscriptions: "/customer/subscriptions",
  customerWishlist: "/customer/wishlist",
  customerReviews: "/customer/reviews",
  customerMessages: "/customer/messages",
  customerReminders: "/customer/reminders",
  customerPoints: "/customer/points",
  customerTransactionHistory: "/customer/transaction-history",
  customerProfile: "/customer/profile",
  customerAgreements: "/customer/agreements",
  customerDiscountCodes: "/customer/discountCodes" /* external app */,
  /*   customerNewsletter: "/customer/newsletter", */
  customerAgreementsIncome: "/customer/agreements/income",
  customerDocuments: "/customer/documents",
  customerRegulations: "/customer/regulations",
  customerHelp: "/customer/help",
  customerData: "/customer/data",
  customerDataEdit: "/customer/data/edit",
  customerDataNewPassword: "/customer/data/edit#editPassword",
  pointsHistory: "/customer/chargesHistory",
  customerPrepaidCards: "/customer/prepaid-cards",
  ordersHistoryDetailsPrepaidCard:
    "/customer/prepaid-cards/orders-history-details/:orderId",

  // prepaid cards
  orderPrepaidCard: "/order-prepaid-card/:cardId",

  // sso
  ssoError: "/sso/error",

  // faq
  faq: "/faq",

  // corporate events
  companyEvents: "/company-events",

  // proposals
  proposals: "/proposals",

  // total-reward
  totalRewardStatement: "/total-reward-statement",

  // create-code-password
  createCodePassword: "/create-code-password/:hash",
};

export const hash = {
  customerWallet: "wallet",
  customerAccount: "account",
  customerSettings: "settings",
  customerHistory: "history",
  customerSubscriptions: "subscriptions",
  customerOtherRealization: "otherRealization",
  customerEditPassword: "editPassword",
  customerService: "service",
};

export const ombRedirect = {
  redirectOperatorToOmb: "/g4noauth/actions/RedirectOperatorToOmb",
  redirectAhrToOmb: "/g4noauth/actions/RedirectAhrToOmb",
};

export const externalLogout = `${process.env.LOGOUT_URL}`;
export const getToken = `/g4noauth/actions/GetToken`;
export const customerEmailEdit =
  "/g4noauth/actions/RedirectToEmailModification?relayState=customer/data";

export const saveSearchTerm = `/public-scripts/search.php`;

export const tourismGetParam = "id";
export const customerProductDetailsGetParam = "productId";
export const externalHelpCenter = "https://pomoc.mybenefit.pl";
