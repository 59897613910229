import { FC } from "react";

import classes from "./createCodePasswordLangSwitch.scss";

interface ICreateCodePasswordLangSwitchProps {
  language: "PL" | "EN";
  setLanguage: (language: "PL" | "EN") => void;
}

const CreateCodePasswordLangSwitch: FC<ICreateCodePasswordLangSwitchProps> = ({
  language,
  setLanguage,
}) => {
  return (
    <div className={classes.root}>
      <button
        type="button"
        className={`${classes.button} ${
          language === "EN" ? classes.selected : ""
        }`}
        onClick={() => setLanguage("EN")}
      >
        EN
      </button>
      <button
        type="button"
        className={`${classes.button} ${
          language === "PL" ? classes.selected : ""
        }`}
        onClick={() => setLanguage("PL")}
      >
        PL
      </button>
    </div>
  );
};

export default CreateCodePasswordLangSwitch;
