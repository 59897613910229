import { Redirect, useParams } from "react-router-dom";

import Loading from "web/Layout/Common/Loading";

import urls from "web/constants/urls";

import { useGetInactiveEmployeeCodesQuery } from "web/features/catalogManagement/catalogManagementApiSlice";

import CreateCodePassword from "./createCodePassword";

const CreateCodePasswordContainer = () => {
  const { hash } = useParams<{ hash: string }>();
  const { isLoading, isError } = useGetInactiveEmployeeCodesQuery(hash || "");

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Redirect to={urls.error} />;
  }

  return <CreateCodePassword />;
};

export default CreateCodePasswordContainer;
