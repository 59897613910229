import { useState } from "react";

import CreateCodePasswordFormContainer from "./CreateCodePasswordForm";
import CreateCodePasswordLangSwitch from "./CreateCodePasswordLangSwitch";
import CreateCodePasswordLinkBox from "./CreateCodePasswordLinkBox";
import classes from "./createCodePassword.scss";

const CreateCodePassword = () => {
  const [language, setLanguage] = useState<"PL" | "EN">("PL");

  return (
    <main className={classes.root}>
      <div className={classes.wrapper}>
        {/* form */}
        <CreateCodePasswordFormContainer language={language} />
        {/* footer */}
        <div className={classes.footer}>
          <CreateCodePasswordLangSwitch
            language={language}
            setLanguage={setLanguage}
          />
          <CreateCodePasswordLinkBox language={language} />
        </div>
      </div>
    </main>
  );
};

export default CreateCodePassword;
