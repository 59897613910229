import { Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import CreateCodePassword from "web/Pages/CreateCodePassword";

import ScrollTopOnRoute from "web/Layout/ScrollTopOnRoute";

import urls from "web/constants/urls";

import Error from "./Error";
import messages from "./Error/messages";
import FAQRoutes from "./FAQRoutes";
import UserRoutes from "./userRoutes";

const Routes = () => (
  <Suspense fallback={<div />}>
    <ScrollTopOnRoute />
    <Switch>
      <Route exact path={urls.error} component={Error} />
      <Route
        exact
        path={urls.browserSettingsError}
        render={(props) => (
          <Error
            {...props}
            hasButton={false}
            message={messages.browserSettingsError}
          />
        )}
      />
      <Route path={urls.faq} component={FAQRoutes} />
      <Route path={urls.createCodePassword} component={CreateCodePassword} />
      <Route path={urls.userRoutes} component={UserRoutes} />
    </Switch>
  </Suspense>
);

export default Routes;
