/* eslint-disable no-script-url */
const createCodePasswordCopy = {
  header: {
    PL: "Utwórz hasło dostępowe do kodów",
    EN: "Create a password to access the codes",
  },
  description: {
    PL: "To hasło umożliwi Ci dostęp do zakupionych kodów, które wyślemy Ci drogą mailową.",
    EN: "This password will allow you to access the purchased codes, which we will send to you by email.",
  },
  newPassword: {
    PL: "Nowe hasło",
    EN: "New password",
  },
  confirmPassword: {
    PL: "Powtórz hasło",
    EN: "Confirm new password",
  },
  confirmButton: {
    PL: "Dalej",
    EN: "Next",
  },
  cancelButton: {
    PL: "Zamknij",
    EN: "Cancel",
  },
  customerService: {
    text: {
      PL: "Centrum pomocy",
      EN: "Customer service",
    },
    link: {
      PL: "https://pomoc.mybenefit.pl/pl",
      EN: "https://pomoc.mybenefit.pl/en",
    },
  },
  privacyPolicy: {
    text: {
      PL: "Polityka prywatności",
      EN: "Privacy policy",
    },
    link: {
      PL: "https://www.mybenefit.pl/polityka-prywatnosci/",
      EN: "https://www.mybenefit.pl/en/privacy-policy/",
    },
  },
  cookieSettings: {
    text: {
      PL: "Ustawienia cookies",
      EN: "Cookie settings",
    },
    link: {
      PL: "javascript:Didomi.preferences.show()",
      EN: "javascript:Didomi.preferences.show()",
    },
  },
  passwordLength: {
    PL: "12 znaków",
    EN: "12 characters",
  },
  passwordNumber: {
    PL: "1 cyfrę",
    EN: "1 number",
  },
  passwordUppercase: {
    PL: "1 dużą literę",
    EN: "1 uppercase letter",
  },
  passwordLowercase: {
    PL: "1 małą literę",
    EN: "1 lowercase letter",
  },
  passwordSpecial: {
    PL: "1 znak specjalny",
    EN: "1 special character",
  },
  successHeader: {
    PL: "Gotowe",
    EN: "Done",
  },
  successDescription1: {
    PL: "Kody wysłaliśmy na Twojego maila.",
    EN: "Codes have been sent to your email.",
  },
  successDescription2: {
    PL: "Przejdź na skrzynkę mailową i użyj utworzonego hasła do otworzenia plików z kodami.",
    EN: "You can go to your email inbox and use the created password to open the codes files.",
  },
};

export default createCodePasswordCopy;
