import { useFormikContext } from "formik";
import { string } from "prop-types";
import { FC } from "react";

import createCodePasswordCopy from "web/Pages/CreateCodePassword/createCodePasswordCopy";

import classes from "./status.scss";

interface IStatusProps {
  name: string;
  language: "PL" | "EN";
}

const Status: FC<IStatusProps> = ({ name: fieldName, language }) => {
  const { values } = useFormikContext<{
    [key: string]: string;
  }>();
  const value = values && (values[fieldName as keyof typeof values] as string);

  const items = [
    {
      name: createCodePasswordCopy.passwordLength[language],
      rule: (value: string) => value?.length >= 12,
    },
    {
      name: createCodePasswordCopy.passwordNumber[language],
      rule: (value: string) => /\d/.test(value),
    },
    {
      name: createCodePasswordCopy.passwordUppercase[language],
      rule: (value: string) => /[A-Z]/.test(value),
    },
    {
      name: createCodePasswordCopy.passwordLowercase[language],
      rule: (value: string) => /[a-z]/.test(value),
    },
    {
      name: createCodePasswordCopy.passwordSpecial[language],
      rule: (value: string) => /[^A-Za-z0-9]/.test(value),
    },
  ];

  return (
    <div className={classes.root}>
      {items.map(({ name, rule }) => {
        const statusClassName =
          value && rule(value as string)
            ? classes.statusFulfilled
            : classes.status;
        return (
          <strong key={name} className={statusClassName}>
            {name}
          </strong>
        );
      })}
    </div>
  );
};

Status.propTypes = {
  name: string.isRequired,
};

export default Status;
