import { useField } from "formik";
import { FC } from "react";

import ValidationErrorMessage from "web/Components/Common/ValidationErrorMessage";

import EyeIcon from "web/assets/icons/eye.svg";

import type { PropsWithClasses } from "web/types/Common";

import classify from "web/classify";

import defaultClasses from "./fieldPassword.scss";

interface IFieldPasswordProps {
  label: string;
  name: string;

  placeholder: string;
  type: string;
  toggleAction: () => void;
  isVisible?: boolean;
  dataT1?: string;
}

const FieldPassword: FC<PropsWithClasses<IFieldPasswordProps>> = ({
  label,
  name,
  classes = {},
  placeholder,
  type,
  toggleAction,
  isVisible = false,
  dataT1 = "password_field",
}) => {
  const [field, meta] = useField({ name });
  const isError = meta.error && meta.touched;
  const inputClassName = isError ? classes.inputError : classes.input;
  const buttonClassName = isVisible ? classes.buttonVisible : classes.button;

  /* eslint-disable react/jsx-props-no-spreading */
  /* eslint-disable jsx-a11y/label-has-associated-control */
  return (
    <>
      <label className={classes.label}>
        {label && <span className={classes.labelText}>{label}</span>}
        <div className={classes.wrapper}>
          <input
            className={inputClassName}
            type={type}
            placeholder={placeholder}
            {...field}
            data-t1={`${dataT1}_${name}_input`}
            spellCheck="false"
          />
          <button
            className={buttonClassName}
            type="button"
            onClick={toggleAction}
            data-t1={`${dataT1}_${name}_button`}
          >
            <EyeIcon className={classes.icon} width={18} />
          </button>
        </div>
      </label>
      <ValidationErrorMessage name={name} />
    </>
  );
};

export default classify<PropsWithClasses<IFieldPasswordProps>>(defaultClasses)(
  FieldPassword
);
