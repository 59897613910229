import { Button } from "@benefit-systems/common-components";
import { Form, useFormikContext } from "formik";
import { FC } from "react";

import urls from "web/constants/urls";

import createCodePasswordCopy from "../createCodePasswordCopy";
import FieldPassword from "./FieldPassword";
import classes from "./createCodePasswordForm.scss";
import fieldClasses from "./field.scss";

interface ICreateCodePasswordFormProps {
  isLoading: boolean;
  language: "PL" | "EN";
}

const CreateCodePasswordForm: FC<ICreateCodePasswordFormProps> = ({
  isLoading,
  language,
}) => {
  const { isValid } = useFormikContext();
  return (
    <Form className={classes.form}>
      <FieldPassword
        key="password"
        name="password"
        label={createCodePasswordCopy.newPassword[language]}
        classes={fieldClasses}
        language={language}
      />
      <FieldPassword
        key="confirmPassword"
        name="confirmPassword"
        label={createCodePasswordCopy.confirmPassword[language]}
        withStatus={false}
        classes={fieldClasses}
        language={language}
      />
      <div className={classes?.buttonWrapper}>
        <Button
          to={urls.customerData}
          variant="secondary"
          type="button"
          size="medium"
          className={classes.button}
        >
          {createCodePasswordCopy.cancelButton[language]}
        </Button>
        <Button
          type="submit"
          disabled={!isValid || isLoading}
          variant="tertiary"
          dataT1="change_password_button"
          size="medium"
          className={classes.button}
        >
          {createCodePasswordCopy.confirmButton[language]}
        </Button>
      </div>
    </Form>
  );
};

export default CreateCodePasswordForm;
