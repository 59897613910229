import { useQuery } from "@apollo/client";
import { isArray, uniq } from "lodash";
import { FC, useMemo } from "react";
import Skeleton from "react-loading-skeleton";

import { ErrorComponentWithContainer } from "web/Layout/Common/ErrorComponent";
import { getLanguageCode } from "web/Layout/Translations/LanguageWrapper";

import generatorQuery from "web/queries/default/generator.graphql";

import jsonParse from "web/utils/data/parser/string/jsonParse";
import isArrayHasItems from "web/utils/data/validator/array/isArrayHasItems";
import newRelicErrorReport from "web/utils/system/essentials/newRelicErrorReport";

import {
  IPageGeneratorModule,
  IParsedParameter,
  IParsedParameters,
} from "web/types/PageGenerator";
import { Nullable } from "web/types/Utils";

import { useAppContext } from "web/context/app";
import { PageGeneratorContextProvider } from "web/context/pageGenerator";
import { useGetCustomerDetailsQuery } from "web/features/customer/customerApiSlice";
import useDataCachedCategoryCounts from "web/features/useDataCached/useDataCachedCategoryCounts";

import getHtmlPromoCount from "web/utils/system/GTM/getHtmlPromoCount";
import { useGetEmployeeDetailsQuery } from "web/features/employee/employeeApiSlice";
import { IEmployee } from "web/types/Employee";
import PageGenerator from "./pageGenerator";

export interface IListingCounter {
  id: number;
  listingPosition: number;
}

export interface IPromoCounter {
  id: number;
  promoPosition: number;
  bannersCount: number;
}

interface IPageGeneratorCategoriesCountQueryProps {
  isMobile: boolean;
  modulesSorted: IPageGeneratorModule[];
  name: string;
  listingCounter: IListingCounter[];
  promoCounter: IPromoCounter[];
  modulesAndCategoriesIdsValues: number[];
}

const PageGeneratorCategoriesCountQuery: FC<
  IPageGeneratorCategoriesCountQueryProps
> = ({
  isMobile,
  modulesSorted,
  name,
  listingCounter,
  promoCounter,
  modulesAndCategoriesIdsValues,
}) => {
  const { data, error, loading } = useDataCachedCategoryCounts({
    ids: uniq(modulesAndCategoriesIdsValues),
  });

  if (error)
    newRelicErrorReport(
      error,
      "web-app/web/utils/system/query/getCategoryCount.js - 21"
    );

  if (loading || !modulesSorted) {
    return (
      <div className="container">
        <Skeleton height="100vh" style={{ marginTop: "50px" }} />
      </div>
    );
  }

  if (error) {
    newRelicErrorReport(
      error,
      "web-app/web/Pages/PageGenerator/pageGeneratorQuery.js - 80"
    );
    console.error(error);
    return <ErrorComponentWithContainer />;
  }

  const providerValue = {
    pageName: name,
  };

  return (
    <PageGeneratorContextProvider value={providerValue}>
      <PageGenerator
        isMobile={isMobile}
        modulesSorted={modulesSorted}
        name={name}
        listingCounter={listingCounter}
        promoCounter={promoCounter}
        availableCategories={data!}
      />
    </PageGeneratorContextProvider>
  );
};

interface IPageGeneratorQueryProps {
  id: Nullable<number>;
  token: string;
  storeId: number;
}

const PageGeneratorQuery: FC<IPageGeneratorQueryProps> = ({
  id,
  token,
  storeId,
}) => {
  const { data: customer } = useGetCustomerDetailsQuery();
  const { language } = customer || {};
  const { isMobile } = useAppContext();

  const options = {
    variables: {
      id,
      type: isMobile ? "mobile" : "desktop",
      token,
      storeId,
      lang: getLanguageCode(),
    },
    context: {
      headers: {
        "content-language": language,
      },
    },
  };
  const { loading, error, data } = useQuery(generatorQuery, options);

  const generator = data?.generator || {};
  const {
    date_from: dateFrom,
    date_to: dateTo,
    modules,
  }: {
    date_from: string;
    date_to: string;
    modules: IPageGeneratorModule[];
  } = generator;

  const [dateFromMemoized, dateToMemoized] = useMemo(() => {
    const from = dateFrom
      ? new Date(dateFrom.replace(/-/g, "/")).getTime()
      : null;
    const to = dateTo ? new Date(dateTo.replace(/-/g, "/")).getTime() : null;
    return [from, to];
  }, [dateFrom, dateTo]);

  const { data: employeeData } = useGetEmployeeDetailsQuery() as { data: IEmployee };
  const { enabledModules = [] } = employeeData || {};

  const modulesSorted = useMemo<IPageGeneratorModule[]>(
    () =>
      isArrayHasItems(modules)
        ? modules.filter(({ parameters = "{}" }) => {
          const { functionality_dependency: functionalityDependency } =
            (jsonParse(parameters) || {}) as { functionality_dependency?: string | string[] };
          if (!functionalityDependency || functionalityDependency === "null" || !functionalityDependency?.length) {
            return true;
          }

          return isArray(functionalityDependency) ? functionalityDependency.every(dependency => enabledModules?.includes(dependency))
            : enabledModules?.includes(functionalityDependency);
        }).sort((first, second) => {
            return first.position - second.position;
          })
        : [],
    [modules, enabledModules]
  );

  const timeNow = Date.now();

  const listingCounter: IListingCounter[] = [];
  const promoCounter: IPromoCounter[] = [];

  if (isArrayHasItems(modulesSorted)) {
    let listingPositionIndex = 1;
    let promoPositionIndex = 1;

    modulesSorted.forEach((item: IPageGeneratorModule) => {
      if (item.type === "product_carousel" || item.type === "two_products") {
        listingCounter.push({
          id: item.id,
          listingPosition: listingPositionIndex,
        });

        listingPositionIndex += 1;

        return listingPositionIndex;
      }

      if (item.type === "slider" || item.type === "triple_graphic") {
        const slidesCount = getPromoCount(jsonParse(item.parameters));
        promoCounter.push({
          id: item.id,
          promoPosition: promoPositionIndex,
          bannersCount: slidesCount,
        });

        promoPositionIndex += slidesCount;

        return promoPositionIndex;
      }

      if (item.type === "html") {
        const promoCount = getHtmlPromoCount(item.parameters);
        if (!promoCount) {
          return null;
        }

        promoCounter.push({
          id: item.id,
          promoPosition: promoPositionIndex,
          bannersCount: promoCount,
        });

        promoPositionIndex += promoCount;

        return promoPositionIndex;
      }
      return null;
    });
  }

  const modulesAndCategoriesIds = modulesSorted
    .map((module) => {
      const parametersParsed: IParsedParameters = jsonParse(
        module?.parameters || ""
      );
      if (module.type === "slider" || module.type === "triple_graphic") {
        const imageCategoryIds =
          Object.keys(parametersParsed)
            ?.map((key) => {
              return (
                typeof parametersParsed?.[key] !== "string" &&
                (parametersParsed[key] as IParsedParameter).image_category_ids
              );
            })
            .filter(Boolean) || [];

        return [imageCategoryIds, parametersParsed?.module_category_ids];
      }

      return parametersParsed?.module_category_ids
        ? parametersParsed.module_category_ids
        : null;
    })
    .filter(Boolean)
    .flat(2) as string[];

  const modulesAndCategoriesIdsValues = modulesAndCategoriesIds
    .map((id) => {
      if (id) {
        const splitId = id.includes(",") ? id.split(",") : id;
        return splitId;
      }
      return null;
    })
    .filter(Boolean)
    .flat()
    .map((val) => +(val as string));

  switch (true) {
    case loading: {
      return (
        <div className="container">
          <Skeleton height="100vh" style={{ marginTop: "50px" }} />
        </div>
      );
    }
    case !!error: {
      newRelicErrorReport(
        error,
        "web-app/web/Pages/PageGenerator/pageGeneratorQuery.js - 273"
      );
      console.error(error);
      return <ErrorComponentWithContainer />;
    }

    case !(
      dateFromMemoized &&
      dateToMemoized &&
      timeNow > dateFromMemoized &&
      dateToMemoized > timeNow &&
      isArrayHasItems(modulesSorted)
    ): {
      return null;
    }

    default: {
      return (
        <PageGeneratorCategoriesCountQuery
          isMobile={isMobile}
          modulesSorted={modulesSorted}
          name={data?.generator?.name}
          listingCounter={listingCounter}
          promoCounter={promoCounter}
          modulesAndCategoriesIdsValues={modulesAndCategoriesIdsValues}
        />
      );
    }
  }
};

const getPromoCount = (params: IParsedParameters) => {
  return Object.keys(params).filter((key) => {
    return typeof params[key] !== "string" && params[key];
  }).length;
};

export default PageGeneratorQuery;
